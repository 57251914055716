var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingData,
              expression: "loadingData",
            },
          ],
          ref: "multipleTable",
          attrs: {
            "empty-text": _vm.textTable,
            "element-loading-text": _vm.$tableLoading,
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": "rgba(255,255,255, 0)",
            "highlight-current-row": "",
            data: _vm.wishesSampleList,
            "cell-style": _vm.tableRowStyle,
            "header-cell-style": _vm.tableHeaderColor,
            "max-height": _vm.$tableMaxHeight,
            border: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              label: "STT",
              width: "60",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "wishesContent", label: "Nội dung" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "receiverType",
              label: "Đối tượng",
              width: "150",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "Nguồn", width: "130", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.idSchool == 0
                      ? _c("span", [_vm._v("Hệ thống")])
                      : _c("span", [_vm._v("Nhà trường")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Ảnh", width: "200", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.urlPicture != null
                      ? _c("el-image", {
                          staticStyle: { width: "150px", height: "150px" },
                          attrs: { src: scope.row.urlPicture, fit: "fill" },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm.checkPermission(["schoolConfig_wishSample_update"])
            ? _c("el-table-column", {
                attrs: { label: "Tác vụ", width: "140", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "success",
                                    disabled: scope.row.idSchool === 0,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.updateWishesSampleMethod(
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Sửa")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "danger",
                                    disabled: scope.row.idSchool === 0,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteWishesSampleMethod(
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Xóa")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  657844887
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.checkPermission(["schoolConfig_wishSample_update"])
        ? _c(
            "div",
            { staticStyle: { float: "right", margin: "15px 0" } },
            [
              _c(
                "el-button",
                {
                  staticClass: "button-bottom",
                  attrs: { type: "success" },
                  on: {
                    click: function ($event) {
                      return _vm.createWishesSampleMethod()
                    },
                  },
                },
                [_vm._v("Thêm mẫu lời chúc")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("WishesSampleCreate", {
        attrs: { dialogVisible: _vm.showWishSampleCreateDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseWishSampleCreateMethod()
          },
        },
      }),
      _c("WishesSampleUpdate", {
        ref: "WishesSampleUpdate",
        attrs: { dialogVisible: _vm.showWishSampleUpdateDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseWishSampleUpdateMethod()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }